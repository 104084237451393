<template>
  <div class="features-block">
    <div class="features">
        <h2>Features</h2>
        <div class="cards">
            <div class="card-top">
                <div class="card">
                    <div class="card-header">
                        <img src="../assets/icons/search_question.svg" />
                        <h3>Project Overview</h3>
                    </div>
                    <p>Get an understanding of your project status and teams’ work</p>
                </div>
                <div class="card">
                    <div class="card-header">
                        <img src="../assets/icons/item.svg" />
                        <h3>Work Items</h3>
                    </div>
                    <p>Create, assign, edit and filter your old, current and potential tasks</p>
                </div>
            </div>
            <div class="card-bottom">
                <div class="card">
                    <div class="card-header">
                        <img src="../assets/icons/backlogs.svg" />
                        <h3>Backlogs</h3>
                    </div>
                    <p>Create, assign, edit and group backlogs and work items</p>
                </div>
                <div class="card">
                    <div class="card-header">
                        <img src="../assets/icons/boards.svg" />
                        <h3>Boards</h3>
                    </div>
                    <p>Keep tracking of your projects and change the status of them</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TheFeatures'
}
</script>

<style scoped>
.features-block {
    margin: 109px 0 112px;
    padding: 16px;
    background: rgba(0, 120, 212, 0.08);
    border-radius: 32px;
}
.features {
    padding: 48px;
    background: #0078D4;
    border-radius: 24px;
}
.features h2 {
    color: #ffffff;
}
.cards {
    display: flex;
    flex-direction: column;
}
.card-top, .card-bottom {
    display: flex;
    flex-direction: row;
}
.card-top {
    margin: 32px 0 24px;
}
.card {
    height: 112px;
    width: 100%;
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    text-align: left;
}
.card-header h3 {
    color: #0078D4;
    margin-left: 8px;
}
.card-top .card:first-child, .card-bottom .card:first-child{
    margin-right: 24px;
}
.card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}
@media (max-width: 1439px) {
    .features {
        padding: 32px 24px 24px;
    }
}
@media (max-width: 1023px) {
    .features-block {
        padding: 16px 0;
        border-radius: 0;
    }
    .features {
        padding: 32px 16px 24px 16px;
        border-radius: 0;
    }
    .features h2 {
        font-size: 24px;
        line-height: 40px;
    }
    .card {
        padding: 16px;
        height: 88px;
    }
    .card-header img {
        width: 16px;
    }
    .card-header h3 {
        font-size: 18px;
        line-height: 32px;
    }
    .card p {
        font-size: 16px;
        line-height: 24px;
    }
}
@media (max-width: 839px) {
    .features {
        padding-bottom: 16px;
    }
    .card-top {
        margin-bottom: 16px;
    }
    .card-top .card:first-child, .card-bottom .card:first-child{
        margin-right: 16px;
    }
}
@media (min-width: 320px) and (max-width: 639px) {
    .card {
        width: auto;
    }
    .card-top, .card-bottom {
        flex-direction: column;
    }
    .card-top .card:first-child, .card-bottom .card:first-child{
        margin-right: 0;
    }
    .card-top .card:first-child, .card-bottom .card:first-child {
        margin-bottom: 16px;
    }
}
</style>