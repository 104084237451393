import { createRouter, createWebHistory } from "vue-router";
import Home from '../viewes/Home'
import PrivacyPolicy from '../viewes/PrivacyPolicy'
import LicenseAgreement from '../viewes/LicenseAgreement'
import NotFound from '../viewes/NotFound'

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/termsofuse",
    name: "LicenseAgreement",
    component: LicenseAgreement,
  },
  {
      path: "/:catchAll(.*)",
      component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
