<template>
    <div class="roles-block">
        <div class="border-bottom"><h2>Roles</h2></div>
        <div class="roles">
            <div class="item-top">
                <div class="item">
                    <div class="circle">
                        <img src="../assets/icons/people.svg" />
                    </div>
                    <h3>Delivery Team</h3>
                    <p>Track your tasks and manage your schedule</p>
                </div>
                <div class="item">
                    <div class="circle">
                        <img src="../assets/icons/list_numbered.svg" />
                    </div>
                    <h3>Project Manager</h3>
                    <p>Get an understanding of your project status and teams’ work</p>
                </div>
            </div>
            <div class="item-bottom">
                <div class="item">
                    <div class="circle">
                        <img src="../assets/icons/business_center.svg" />
                    </div>
                    <h3>Stakeholders</h3>
                    <p>Get project updates and be in touch with project and team</p>
                </div>
                <div class="item">
                    <div class="circle">
                        <img src="../assets/icons/search.svg" />
                    </div>
                    <h3>Tester</h3>
                    <p>Track project progress and set you set your tasks</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheRoles'
}
</script>

<style scoped>
.roles-block{
    margin: 0 24px 128px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.roles {
    display: flex;
    flex-direction: row;
    margin-top: 144px;
}
.item-top, .item-bottom {
    display: flex;
    flex-direction: row;
}
.item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 88px;
    border: 4px solid #D3E8F9;
    border-radius: 50%;
}
.item h3 {
    margin: 16px 0 8px;
    color: #0078D4;
}
@media (max-width: 1439px) {
    .roles-block {
        margin-left: 48px;
        margin-right: 48px;
    }
    .roles {
        margin-top: 120px;
        flex-direction: column;
    }
    .item-top {
        margin-bottom: 64px;
    }
    .item {
        width: 456px;
    }
}
@media (max-width: 1023px) {
    .roles-block {
        margin-bottom: 64px;
    }
    .roles {
        margin-top: 96px;
    }
    .roles-block h2 {
        font-size: 24px;
        line-height: 40px;
    }
    .item-top {
        margin-bottom: 52px;
    }
    .item {
        width: 392px;
    }
    .item h3 {
        font-size: 18px;
        line-height: 32px;
    }
    .item p {
        font-size: 16px;
        line-height: 24px;
    }
}
@media (max-width: 839px) {
    .roles-block{
        margin: 0 32px;
    }
    .item {
        width: 296px;
    }
}
@media (min-width: 320px) and (max-width: 639px) {
    .roles-block {
        margin: 0 16px;
    }
    .roles {
        margin-top: 90px;
    }
    .item-top, .item-bottom {
        flex-direction: column;
    }
    .item {
        width: 288px;
    }
    .item-top .item:first-child, .item-bottom .item:first-child {
        margin-bottom: 48px;
    }
}
</style>