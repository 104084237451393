<template>
  <div class="description">
    <h2>
      <span>Manage</span> now your projects easily, <br class="br-d" />fast and
      in any time <br class="br-m" /><span class="word-wrap"
        >from your phone!</span
      >
    </h2>
    <div class="description-blocks">
      <div class="video-container">
        <video controls autoplay>
          <source src="../assets/media/devops-mobile.mp4" type="video/mp4">
        </video>
      </div>
      <div class="text">
        <p>
          If you are a Project Manager, Developer or Tester then this is the
          most suitable solution for you and your team if you are already using
          the web version of <span>Microsoft Azure.</span>
        </p>
        <p>
          The app supports only <span>original project</span> types but we are
          working to add support for custom projects as well, and there are
          included <span>all the basic features of the web version</span> with
          slightly improvements and differences to ensure the perfect user
          experience.
        </p>
        <p>
          <span>DevOps Mobile</span> uses the API’s provided by
          <span>Microsoft Azure DevOps</span> to keep track of your projects
          more easily from your mobile.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheDescription"
}
</script>

<style scoped>
.description {
  text-align: center;
  margin: 0 16px;
}
video {
  width: 600px;
  height: 338px;
  background-color: #1a6a9b;
  border-radius: 8px;
}
video:focus {
  outline: none;
}
.br-d {
  display: block;
}
.br-m {
  display: none;
}
.description-blocks {
  display: flex;
  flex-direction: row;
  margin-top: 65px;
}
.text {
  text-align: left;
  margin: 0 16px 0 24px;
}
p:nth-child(2) {
  margin: 24px 0;
}
@media (max-width: 1439px) {
  .description h2 {
    font-size: 24px;
    line-height: 40px;
  }
  .description-blocks {
    flex-direction: column;
    margin: 0;
  }
  video {
    margin: 32px 0;
    width: 616px;
    height: 344px;
  }
  .text {
    margin: 0;
  }
  p > span {
    font-family: "OpenSans";
    font-weight: normal;
  }
}
@media (max-width: 1023px) {
  .text p {
    font-size: 16px;
  }
  p:nth-child(2) {
    margin: 8px 0;
  }
  video {
    margin-bottom: 24px;
    width: 600px;
    height: 336px;
  }
}
@media (max-width: 839px) {
  .video-container {
    width: 100%;
    height: 100%;
  }
  video {
    width: inherit;
  }
}
@media (min-width: 320px) and (max-width: 639px) {
  .description {
    text-align: left;
  }
  .br-d {
    display: none;
  }
}
@media (max-width: 508px) {
  .br-m {
    display: block;
  }
}
</style>