<template>
    <div>
      <div class="center">
        <TheHeader/>
        <div class="body">
            <TheDescription/>
            <TheFeatures/>
            <TheRoles/>
            <TheContacts/>
        </div>
      </div>
      <TheFooter/>
    </div>
</template>

<script>
import TheHeader from '../components/TheHeader.vue'
import TheDescription from '../components/TheDescription.vue'
import TheFeatures from '../components/TheFeatures.vue'
import TheRoles from '../components/TheRoles.vue'
import TheContacts from '../components/TheContact.vue'
import TheFooter from '../components/TheFooter.vue'

export default {
    name: 'Home',
    components: {
        TheHeader,
        TheDescription,
        TheFeatures,
        TheRoles,
        TheContacts,
        TheFooter,
    }
}
</script>

<style>
h1 {
  font-family: "OpenSans-ExtraBold";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 72px;
}
h2 {
  font-family: "OpenSans-ExtraBold";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 56px;
}
h2>span {
  padding: 0 4px;
  color: #ffffff;
  background-color: #0078D4;
  border-radius: 8px;
}
.border-bottom {
  width: fit-content;
  border-radius: 16px;
  height: 8px;
  box-shadow: 0 56px 0 0 #0078D4;
}
h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
}
h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
}
h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
}
h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
}
p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}
li {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}
p>span {
  font-family: "OpenSans-ExtraBold";
  font-weight: bold;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
a {
  text-decoration: none;
}
.body {
  margin: 160px 92px 136px 92px;
}
.center {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1439px) {
  .center {
    max-width: 1024px;
  }
  h1 {
    font-size: 36px;
    line-height: 56px;
  } 
  h6 {
    font-size: 16px;
    line-height: 32px;
  }
  .body {
    margin: 122px 28px 128px 28px;
  }
}
@media (max-width: 1023px) {
  h1 {
    font-size: 24px;
    line-height: 40px;
  }
  .border-bottom {
    box-shadow: 0 40px 0 0 #0078D4;
  }
   .body {
    margin: 124px 0 64px;
  }
}
@media (max-width: 839px) {
  .body{
    margin-top: 112px;
    margin-bottom: 64px;
  }
}
@media (min-width: 320px) and (max-width: 639px) {
  .body{
    margin-top: 64px;
  }
}
</style>