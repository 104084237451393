<template>
    <div class="footer">
        <div class="bottom">
            <div class="left-block">
                <router-link :to="{ name: 'Home' }"><img src="../assets/icons/logo_white.svg" alt="DevOps Mobile Logo"></router-link>
                <div class="stores-block">
                    <a href="https://play.google.com/store/apps/details?id=cigen.me.cigendevops&gl=US">
                        <img src="../assets/images/google-play-button.svg" /></a>
                    <a href="/">
                        <img href="#" src="../assets/images/app-store-button.svg" />
                        </a>
                </div>
            </div>
            <div class="links">
                <h3>Quick Links</h3>
                <router-link :to="{ name: 'LicenseAgreement' }"><p>Terms & Conditions</p></router-link>
                <router-link :to="{ name: 'PrivacyPolicy' }"><p>Privacy Policy</p></router-link>
            </div>
            <div class="right-block">
                <div class="d-flex">
                    <p>Developed by</p>
                    <a href="https://cigen.xyz/"><img src="../assets/icons/logo_cigen.svg" alt="CIGen Logo"></a>
                </div>
                <p>©2024 DevOps Mobile. All Rights Reserved.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheFooter'
}
</script>

<style scoped>
.footer {
    background-color: #2E2E2E;
}
.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 64px 108px;
    text-align: left;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;;
}
.stores-block a:first-child img {
    margin: 30px 16px 0 0;
    width: 162px;
    height: 48px;
}
.stores-block a:last-child img {
    width: 144px;
    height: 48px;
}
.bottom h3, .bottom p {
    color: #FFFFFF;
}
.links h3 {
    margin-bottom: 8px;
}
.links p {
    margin-top: 8px;
}
.links p:hover {
    text-decoration-line: underline;
}
.links p:active {
    text-decoration-line: underline;
    color: rgba(255, 255, 255, 0.64);
}
.d-flex {
    display: flex;
    justify-content: flex-start;
}
.d-flex p {
    margin-right: 8px;
}
.right-block:last-child {
    font-size: 12px;
}
@media (max-width: 1439px) {
    .bottom {
        padding: 64px 44px;
        max-width: 1024px;
    }
    .right-block {
        align-self: flex-end;
    }
}
@media (max-width: 1023px) {
    .bottom {
        flex-direction: column;
        padding: 64px 0;
        text-align: center;
    }
    .right-block {
        align-self: center;
    }
    .d-flex {
        justify-content: center;
    }
    .links {
        margin: 64px 0;
    }
}
@media (min-width: 320px) and (max-width: 639px) {
    .bottom {
        padding: 32px 16px;
        text-align: left;
    }
    .left-block img{
        width: 212px;
        height: 24px;
    }
    .stores-block a:first-child img {
        margin: 24px 16px 0 0;
        width: 135px;
        height: 40px;
    }
    .stores-block a:last-child img { 
        width: 120px;
        height: 40px;
    }
    .links {
        margin: 32px 0;
    }
    .right-block {
        align-self: flex-start;
    }
    .d-flex {
        justify-content: flex-start;
    }
}
</style>