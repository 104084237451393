<template>
  <div class="contacts">
    <div class="mockups">
      <img src="../assets/images/mockups-small.svg" />
    </div>
    <div class="circle-b">
      <img
        src="../assets/icons/ellipse-blured.svg"
        alt="Backgroung blue circle"
      />
    </div>
    <div class="contacts-form">
      <div class="border-bottom"><h2>Contacts</h2></div>
      <p>We are happy to get in touch and get your feedback!</p>
      <form class="form" @submit.prevent="handleSubmit">
        <input
          name="email"
          type="text"
          v-model="form.email"
          placeholder="Enter your email..."
          v-bind:class="errorEmail == '' ? null : 'errorBorder'"
        />
        <p class="error-msg">{{ errorEmail }}</p>
        <br />
        <textarea
          name="message"
          type="text"
          v-model="form.message"
          placeholder="Enter your message..."
          maxlength="300"
          v-bind:class="errorMessage == '' ? null : 'errorBorder'"
        />
        <p class="error-msg">{{ errorMessage }}</p>
        <br />
        <button class="button" type="submit">
          SEND
        </button>
      </form>
      <div id="popup">
        <h6>Your message has been sent successfully!</h6>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ContactForm } from "../services/api";

const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: "TheContacts",
  data() {
    return {
      form: {
        email: "",
        message: "",
      },
      errorEmail: "",
      errorMessage: "",
      errorBorder: {
        border: "1px solid #cf3917",
      },
      popupElement: null,
    };
  },
  mounted() {
    this.popupElement = document.getElementById("popup");
  },
  methods: {
    async handleSubmit() {
      if (this.form.email && regex.test(this.form.email) && this.form.message) {
        this.errorEmail = this.message = "";
        const response = await axios.post(
          ContactForm,
          this.form
        );
        if (response.error) {
          console.error(response.error);
        } else {
          this.popupElement.classList.add("visuallyhidden");
          setTimeout(() => {
            this.popupElement.classList.remove("visuallyhidden");
          }, 5000);
        }
        this.form.email = this.form.message = "";
      } else {
        if (!regex.test(this.form.email)) {
          this.errorEmail = "This field must be a valid email";
        }
        if (!this.form.email) {
          this.errorEmail = "This field is required";
        }
        if (!this.form.message) {
          this.errorMessage = "This field is required";
        }
        if (this.form.email && regex.test(this.form.email)) {
          this.errorEmail = "";
        }
        if (this.form.message) {
          this.errorMessage = "";
        }
      }
    },
  },
};
</script>

<style scoped>
#popup {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s linear;
  display: flex;
  align-items: center;
  padding: 24px;
  width: 24em;
  height: 6em;
  position: fixed;
  bottom: 1em;
  right: 1em;
  background-color: #d3e8f9;
  border-radius: 8px;
  box-shadow: 3px 2px 9px 6px rgb(0 120 212 / 8%);
}
#popup.visuallyhidden {
  visibility: visible;
  opacity: 1;
}
#popup h6 {
  color: #0078d4;
}
.contacts {
  display: flex;
  flex-direction: row;
  margin: 0 16px;
  z-index: 1;
}
p {
  margin: 64px 0 48px;
}
.mockups,
.contacts-form {
  flex: 1;
  z-index: 1;
}
.circle-b {
  display: none;
  position: relative;
  bottom: -98px;
  left: -1202px;
  z-index: 0;
}
.circle-b img {
  width: 1238px;
  height: 519px;
  object-fit: cover;
  object-position: 10% 10%;
  position: absolute;
  z-index: 0;
}
.contacts-form {
  text-align: left;
}
.form {
  display: flex;
  flex-direction: column;
}
input[type="text"],
textarea[type="text"] {
  padding: 12px 24px;
  border: 1px solid rgba(46, 46, 46, 0.48);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
}
input[type="text"].errorBorder,
input[type="text"].errorBorder:focus,
input[type="text"].errorBorder:hover,
textarea[type="text"].errorBorder,
textarea[type="text"].errorBorder:focus,
textarea[type="text"].errorBorder:hover {
  border: 1px solid #cf3917;
}
textarea[type="text"] {
  height: 166px;
  resize: none;
}
p.error-msg {
  padding-top: 10px;
  font-size: 12px;
  color: #cf3917;
  margin: 0;
}
.button {
  height: 56px;
  background: #0078d4;
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
}
button:hover {
  background: #4ba0e1;
}
button:active {
  background: #89c0eb;
}
input:focus,
textarea:focus,
button:focus {
  outline: none;
}
input:hover,
textarea:hover {
  border: 1px solid rgba(0, 120, 212, 0.48);
}
input[type="text"]:focus,
textarea[type="text"]:focus {
  border: 1px solid #0078d4;
  color: rgba(0, 0, 0, 0.82);
}
@media (max-width: 1439px) {
  #popup {
    width: 14em;
    height: 3em;
  }
  .contacts {
    margin: 0 176px;
  }
  .circle-b {
    display: none;
  }
  .contacts-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form {
    width: 100%;
  }
  .mockups {
    display: none;
  }
}
@media (max-width: 1023px) {
  .contacts {
    margin: 0 120px;
  }
  .contacts h2 {
    font-size: 24px;
    line-height: 40px;
  }
  .contacts-form p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 839px) {
  .contacts {
    display: none;
  }
}
</style>
