<template>
    <div class="top">
        <router-link :to="{ name: 'Home' }"><img class="logo" alt="DevOps Mobile logo" src="../assets/icons/logo.svg" /></router-link>
        <div class="mobile-mockups">
            <img src="../assets/images/mobile-mockups.svg" />
        </div>
        <div class="left-block">
            <div class="text-block">
                <h1>Build, Test, Develop and Manage Project through only one App!</h1>
                <h6>The perfect mobile friendly version of Microsoft Azure DevOps.</h6>
            </div>
            <div class="stores-block">
                <a href="https://play.google.com/store/apps/details?id=cigen.me.cigendevops&gl=US">
                    <img src="../assets/images/google-play-button.svg" />
                </a>
                <a href="#"><img href="#" src="../assets/images/app-store-button.svg" /></a>
            </div>
        </div>
        <div class="mockups">
            <img src="../assets/images/hero-illustration.svg" />
        </div>
        <div class="circle">
            <img src="../assets/icons/circle-top.svg" alt="Backgroung blue circle">
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheHeader'
}
</script>

<style scoped>
.top {
    display: flex;
    flex-direction: row;
    margin: 64px 108px;
}
.top img.logo {
    width: min-content;
    position: absolute;
}
.mobile-mockups {
    display: none;
}
.left-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
}
.text-block {
    text-align: left;
    margin: 88px 0 48px 0;
}
.text-block h1 {
    margin-bottom: 24px;
}
.stores-block a:first-child img {
    margin-right: 24px;
}
.mockups {
    top: 32px;
    position: relative;
    z-index: 1;
}
.mockups img {
    width: 600px;
    height: 544px;
}
.circle {
    position: absolute;
    top:0;
    right: 0;
    z-index: 0;
}
@media (max-width: 1439px) {
    .top {
        margin: 64px 44px;
    }
    .top img.logo {
        width: 212px;
        height: 24px;
    }
    .text-block {
        margin: 64px 0 32px 0;
    }
    .text-block h1 {
        margin-bottom: 16px;
    }
    .stores-block a:first-child img{
        width: 135px;
        height: 40px;
        margin-right: 16px;
    }
    .stores-block img {
        width: 120px;
        height: 40px;
    }
    .mockups {
        top: 0;
    }
    .mockups img {
        width: 456px;
        height: 414px;
    }
    .circle img {
        width: 444px;
        height: 572px;
    }
}
@media (max-width: 1023px) {
    .top {
        margin: 32px 16px;
    }
    .mockups {
        top: 12px;
    }
    .mockups img {
        width: 392px;
        height: 356px;
    }
    .circle img {
        width: 412px;
        height: 500px;
    }
}
@media (max-width: 839px) {
    .text-block {
        margin-top: 48px;
    }
    .mockups {
        top: 24px;
    }
    .mockups img {
        width: 296px;
        height: 268px;
    }
    .circle img {
        width: 312px;
    }
}
@media (min-width: 320px) and (max-width: 639px) {
    .top {
        flex-direction: column;
        margin: 0;
    }
    .top a:first-child img.logo {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }
    .mobile-mockups {
        display: flex;
        width: 100%;
    }
    .mobile-mockups img {
        width: inherit;
    }
    .left-block {
        margin: 0 16px;
    }
    .text-block {
        margin: 24px 0;
    }
    .mockups, .circle {
        display: none;
    }
}
</style>