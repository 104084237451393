<template>
  <div class="notfound">
    <router-link :to="{ name: 'Home' }">
      <img class="logo" alt="DevOps Mobile logo" src="../assets/icons/logo.svg"
    /></router-link>
    <h1>Oops, it looks like the page you're looking for doesn't exist.</h1>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style>
.notfound{
  text-align: left;
  margin: 56px;
}
</style>
