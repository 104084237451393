<template>
  <div>
    <div class="policy">
      <router-link :to="{ name: 'Home' }">
        <img
          class="logo"
          alt="DevOps Mobile logo"
          src="../assets/icons/logo.svg"
      /></router-link>
      <h1>Mobile DevOps App Privacy Policy</h1>

      <p class="grey">Last Updated: May 11, 2024</p>
      <p>
        Protecting your privacy is really important to us. With this in mind,
        we’re providing this Privacy Policy to explain our practices regarding
        the collection, use and disclosure of information that we receive
        through our Services. This Privacy Policy does not apply to any
        third-party websites, services or applications, even if they are
        accessible through our Services. Also, please note that, unless we
        define a term in this Privacy Policy, all capitalized terms used in this
        Privacy Policy have the same meanings as in our Terms of Service. So,
        please make sure that you have read and understand our Terms of Service.
      </p>

      <h4>Revisions to this Privacy Policy</h4>
      <p>
        Any information that is collected via our Services is covered by the
        Privacy Policy in effect at the time such information is collected. We
        may revise this Privacy Policy from time to time. If we make any
        material changes to this Privacy Policy, we’ll notify you of those
        changes by posting them on the Services or through other communications
        to you, and we’ll update the “Last Updated Date” above to indicate when
        those changes will become effective.
      </p>

      <h3>Collection and Use of Information</h3>

      <h4>Information Collected or Received from You</h4>
      <p>
        We may ask you to provide us with certain personally identifiable
        information (“Personal Information”) that can be used to contact you.
        Personal Information may include, but is not limited to:
      </p>
      <ul>
        <li>First and last name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <!-- <li>Home or business address</li> -->
        <!-- <li>Other contact information</li> -->
        <!-- <li>Credit/debit card information in order to pay for Services</li> -->
        <!-- <li>Usage data</li> -->
      </ul>

      <h4>Information That We Share with Third Parties</h4>

      <p>
        We do not collect information regarding your projects, tasks, team
        members, etc. All providing information got directly from Microsoft
        Azure DevOps Api and not stored or transited our servers. We will not
        share any of your information that we have collected from or regarding
        you except as described below:
      </p>

      <h5>Information Shared with Our Services Providers</h5>
      <p>
        The information collected will be shared with Microsoft Azure Devops API
        providing services to you. The information will only be used in
        connection with our services.
      </p>
      <p>
        In addition, your credit/debit card or account information may be shared
        with financial institutions to enable payments for Services.
      </p>

      <h5>Information Shared with Third Parties</h5>
      <p>
        We may share aggregated information and non-identifying information with
        third parties for industry research and analysis, demographic profiling
        and other similar purposes. We may also share your Personal Information
        - as permitted or allowed by law - to contractors, service providers,
        and other third parties we use to support our business and who are bound
        by contractual obligations to keep Personal Information confidential and
        use it only for the purposes for which we disclose it to them.
      </p>

      <h5>
        Information Disclosed in Connection with Business Transactions
      </h5>
      <p>
        Information that we collect from our users is considered to be a
        business asset. Thus, if we are acquired by a third party as a result of
        a transaction such as a merger, acquisition or asset sale or if our
        assets are acquired by a third party in the event we go out of business
        or enter bankruptcy, some or all of our assets, including your Personal
        Information, may be disclosed or transferred to a third party acquirer
        in connection with the transaction.
      </p>

      <h5>
        Information Disclosed for Our Protection and the Protection of Others
      </h5>
      <p>
        We cooperate with government and law enforcement officials or private
        parties to enforce and comply with the law. We may disclose any
        information about you to government or law enforcement officials or
        private parties as we, in our sole discretion, believe necessary or
        appropriate: (i) to respond to claims, legal process (including
        subpoenas); (ii) to protect our property, rights and safety and the
        property, rights and safety of a third party or the public in general;
        and (iii) to stop any activity that we consider illegal, unethical or
        legally actionable activity.
      </p>

      <h4>Your Choices</h4>
      <p>
        We offer you choices regarding the collection, use and sharing of your
        information and we’ll respect the choices you make. Please note that if
        you decide not to provide us with the information that we request, you
        may not be able to access all of the features of the Services.
      </p>

      <h5>Opt-Out</h5>
      <p>
        We may periodically send you free newsletters, texts, and/or e-mails
        that directly promote our Services. When you receive such promotional
        communications from us, you will have the opportunity to “opt-out.” We
        do need to send you certain communications regarding the Services and
        you will not be able to opt out of those communications – e.g.,
        communications regarding updates to our Terms of Use or this Privacy
        Policy or information about billing.
      </p>

      <h4>The Security of Your Information</h4>
      <p>
        We take reasonable administrative, physical and electronic measures
        designed to protect the information that we collect from or about you
        from unauthorized access, use or disclosure. When you enter sensitive
        information through Mobile DevOps App, we encrypt this data using SSL or
        other technologies. Please be aware, however, that no method of
        transmitting information over the Internet or storing information is
        completely secure. Accordingly, we cannot guarantee the absolute
        security of any information.
      </p>
      <p>
        Mobile DevOps App hereby disclaims any and all liability for any type of
        damages including, but not limited to, indirect, incidental,
        consequential or special damages, losses or expenses, for the security
        breaches due to our actions or lack of actions, and our customer
        information practices.
      </p>

      <h5>Phishing Warning</h5>
      <p>
        “Phishing” is a scam designed to steal personal information. If you
        receive an e-mail that looks like it is from Mobile DevOps App asking
        for your personal information, you should not respond. We will never
        request your password, username, or other personal information through a
        non-secure or unsolicited e-mail or telephone communication.
      </p>

      <h5>Links to Other Sites</h5>
      <p>
        Our Services may contain links to websites and services that are owned
        or operated by third parties (each, a “Third-Party Service”). Any
        information that you provide on or to a Third-Party Service or that is
        collected by a Third-Party Service is provided directly to the owner or
        operator of the Third-Party Service and is subject to the owner’s or
        operator’s privacy policy. We’re not responsible for the content,
        privacy or security practices and policies of any Third-Party Service.
        To protect your information we recommend that you carefully review the
        privacy policies of all Third-Party Services that you access.
      </p>

      <h4>Questions?</h4>
      <p>If you have any questions, please contact us at:</p>
      <div class="address">
        <p>CIGen Services, LLC</p>
        <p>14 Uhorska str., 79034,</p>
        <p>L'viv, Ukraine</p>
        <p>
          <a href="mailto:support@devopsmobile.app">support@devopsmobile.app</a>
        </p>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter";
export default {
  name: "PrivacyPolicy",
  components: {
    TheFooter,
  },
};
</script>

<style>
.policy {
  text-align: left;
  margin: 64px 108px;
}
.policy h1 {
  margin-top: 16px;
  margin-bottom: 10px;
}
.policy p.grey {
  margin-bottom: 24px;
  color: rgb(155, 155, 155);
}
.policy h3 {
  margin-top: 56px;
}
.policy h4 {
  margin-top: 38px;
}
.policy h5 {
  margin-top: 32px;
}
.policy p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.policy .address p {
  margin: 0;
}
.policy .address p > a {
  color: #2e2e2e;
}
.policy .address p > a:hover {
  text-decoration-line: underline;
}
@media (max-width: 1439px) {
  .policy {
    margin: 64px 44px;
  }
}
@media (max-width: 1023px) {
  .policy {
    margin: 32px 16px;
  }
}
@media (min-width: 320px) and (max-width: 639px) {
  .policy {
    margin: 32px 16px;
  }
  .policy h3 {
    margin-top: 40px;
  }
  .policy h4 {
    margin-top: 32px;
  }
  .policy h5 {
    margin-top: 24px;
  }
}
</style>
