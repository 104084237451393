<template>
  <router-view :to="{ name: 'Home' }" />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "OpenSans";
  src: url("assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSans-ExtraBold";
  src: url("assets/fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
}
html,
body,
#app {
  margin: 0;
  height: 100%;
}
#app {
  font-family: "OpenSans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2e2e2e;
}
</style>
